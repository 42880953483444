/* eslint-disable  no-unused-vars */
import {createRouter, createWebHistory, useRoute} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import RouteNames from "@/routeNames";
import Webservice from "@/webservice";

// IMPORTANT: you need to add route to the 2 websites !

// routes for the french website
const routes_fr = [
  {
    path: '/',
    name: RouteNames.HOME,
    component: HomeView
  },
  {
    path: '/boutique',
    redirect:'/creations',
    component: () => import('../views/StoreMain.vue')
  },
  {
    path: '/creations',
    name: RouteNames.STORE,
    component: () => import('../views/StoreMain.vue')
  },
  {
    path: '/product/:slug/:productId/',
    name: RouteNames.PRODUCT_DETAIL_V2,
    component: () => import('../views/ProductDetailV2.vue'),
    props: route => ({
      slug: route.params.slug,
      productId: route.params.productId,
    })
  },
  {
    path: '/mon-panier',
    name: RouteNames.CART,
    component: () => import('../views/CartV2.vue'),
  },
  {
    path: '/commande/adresse',
    name: RouteNames.ADDRESS_CHECKOUT,
    component: () => import('../views/AddressCheckout.vue'),
  },
  {
    path: '/commande/expedition',
    name: RouteNames.SHIPPING,
    component: () => import('../views/ShippingView.vue'),
  },
  {
    // Warning if change, update backend (callback link)
    path: '/commande/paiement',
    name: RouteNames.PAYMENT_METHOD_SELECTION,
    component: () => import('../views/PaymentMethodSelection.vue'),
  },
  {
    path: '/commande/paiement/carte-bancaire',
    name: RouteNames.PAYMENT_STRIPE,
    component: () => import('../views/PaymentStripe.vue'),
  },
  {
    path: '/commande/paiement/cheque',
    name: RouteNames.PAYMENT_CHEQUE,
    component: () => import('../views/PaymentCheque.vue'),
  },
  {
    path: '/commande/paiement/paypal',
    name: RouteNames.PAYMENT_PAYPAL,
    component: () => import('../views/PaymentPaypal.vue'),
  },
  {
    path: '/commande/paiement/virement',
    name: RouteNames.PAYMENT_WIRE,
    component: () => import('../views/PaymentVirement.vue'),
  },
  {
    // Warning if change, update backend (callback link)
    path: '/commande/succes',
    name: RouteNames.SUCCESS,
    component: () => import('../views/OrderSuccess.vue'),
  },
  // La maison
  {
    path: '/realisations-sur-mesure',
    name: RouteNames.SUR_MESURE,
    component: () => import('../views/OnboardingWelcome.vue'),
  },
  {
    path: '/le-joaillier',
    name: RouteNames.THE_JEWELLER,
    component: () => import('../views/CompanyHistory.vue'),
  },
  {
    path: '/production-artisanale',
    name: RouteNames.ARTISANAL,
    component: () => import('../views/ArtisanalView.vue'),
  },
  {
    path: '/presse',
    name: RouteNames.PRESSE,
    component: () => import('../views/PressView.vue'),
  },
  {
    path: '/mentions-legales',
    name: RouteNames.LEGAL_MENTIONS,
    component: () => import('../views/LegalMentions.vue'),
  },
  {
    path: '/conditions-generales-de-vente',
    name: RouteNames.CGV,
    component: () => import('../views/CGVView.vue'),
  },
  {
    path: '/livraison',
    name: RouteNames.SHIPPING_CONDITIONS,
    component: () => import('../views/ShippingConditions.vue'),
  },
  {
    path: '/retours',
    name: RouteNames.RETOURS,
    component: () => import('../views/RetoursConditions.vue'),
  },
  {
    path: '/nous-contacter',
    name: RouteNames.CONTACT_US,
    component: () => import('../views/ContactUs.vue'),
  },
    // Old link with orthographic error
  {
    path: '/bagues-fillacailles-alliances-sur-mesure',
    name:RouteNames.MARIAGE,
    redirect: to =>{
      return {name: RouteNames.SUR_MESURE}
    },
  },
  {
    path: '/bagues-fillancailles-alliances-sur-mesure',
    redirect: to =>{
      return {name: RouteNames.SUR_MESURE}
    },
  },
  {
    path: '/alliances-sur-mesure-artisan-art-paris',
    name: RouteNames.ALLIANCES_SUR_MESURE_PARIS,
    component: () => import('../views/ContentAlliancesParis.vue'),
  },
  {
    path: '/evenements',
    name: RouteNames.EVENTS,
    component: () => import('../views/EventsView.vue'),
  },
  {
    path: '/creation-bague-sur-mesure-unique',
    props:{
      title:"Atelier Bespoke",
      subtitle:"Bague sur Mesure unique",
      landingId:"LP_B"
    },
    name: RouteNames.LP1,
    component: () => import('../views/LandingPage1.vue'),
  },
  {
    path: '/bague-fiancailles-sur-mesure-unique',
    props:{
      title:"Atelier Bespoke",
      subtitle:"Bague de Fiançaille sur Mesure",
      landingId:"LP_F"
    },
    name: RouteNames.LP2,
    component: () => import('../views/LandingPage1.vue'),
  },
  {
    path: '/joaillerie-sur-mesure-unique',
    props:{
      title:"Atelier Bespoke",
      subtitle:"Joaillerie sur Mesure",
      landingId:"LP_J"
    },
    name: RouteNames.LP3,
    component: () => import('../views/LandingPage1.vue'),
  },
  {
    path: '/rendez-vous-confirme',
    name: RouteNames.RDV_CONFIRME,
    component: () => import('../views/RDVConfirme.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: RouteNames.NOT_FOUND,
    component: () => import('../views/NotFound.vue'),
  },
]
// routes for the en website
const routes_en = [
  {
    path: '/',
    name: RouteNames.HOME,
    component: HomeView
  },
  {
    path: '/store',
    redirect: '/creations',
    component: () => import('../views/StoreMain.vue')
  },
  {
    path: '/creations',
    name: RouteNames.STORE,
    component: () => import('../views/StoreMain.vue')
  },
  {
    path: '/product/:slug/:productId/',
    name: RouteNames.PRODUCT_DETAIL_V2,
    component: () => import('../views/ProductDetailV2.vue'),
    props: route => ({
      slug: route.params.slug,
      productId: route.params.productId,
    })
  },
  {
    path: '/my-cart',
    name: RouteNames.CART,
    component: () => import('../views/CartV2.vue'),
  },
  {
    path: '/order/address',
    name: RouteNames.ADDRESS_CHECKOUT,
    component: () => import('../views/AddressCheckout.vue'),
  },
  {
    path: '/order/shipping',
    name: RouteNames.SHIPPING,
    component: () => import('../views/ShippingView.vue'),
  },
  {
    // Warning if change, update backend (callback link)
    path: '/order/payment',
    name: RouteNames.PAYMENT_METHOD_SELECTION,
    component: () => import('../views/PaymentMethodSelection.vue'),
  },
  {
    path: '/order/payment/card',
    name: RouteNames.PAYMENT_STRIPE,
    component: () => import('../views/PaymentStripe.vue'),
  },
  {
    path: '/order/payment/check',
    name: RouteNames.PAYMENT_CHEQUE,
    component: () => import('../views/PaymentCheque.vue'),
  },
  {
    path: '/order/payment/paypal',
    name: RouteNames.PAYMENT_PAYPAL,
    component: () => import('../views/PaymentPaypal.vue'),
  },
  {
    path: '/order/payment/wire',
    name: RouteNames.PAYMENT_WIRE,
    component: () => import('../views/PaymentVirement.vue'),
  },
  {
    // Warning if change, update backend (callback link)
    path: '/order/success',
    name: RouteNames.SUCCESS,
    component: () => import('../views/OrderSuccess.vue'),
  },
  // La maison
  {
    path: '/bespoke',
    name: RouteNames.SUR_MESURE,
    component: () => import('../views/OnboardingWelcome.vue'),
  },
  {
    path: '/the-jeweller',
    name: RouteNames.THE_JEWELLER,
    component: () => import('../views/CompanyHistory.vue'),
  },
  {
    path: '/handmade-production',
    name: RouteNames.ARTISANAL,
    component: () => import('../views/ArtisanalView.vue'),
  },
  {
    path: '/press',
    name: RouteNames.PRESSE,
    component: () => import('../views/PressView.vue'),
  },
  {
    path: '/legal-mentions',
    name: RouteNames.LEGAL_MENTIONS,
    component: () => import('../views/LegalMentions.vue'),
  },
  {
    path: '/terms-and-conditions',
    name: RouteNames.CGV,
    component: () => import('../views/CGVView.vue'),
  },
  {
    path: '/shipping',
    name: RouteNames.SHIPPING_CONDITIONS,
    component: () => import('../views/ShippingConditions.vue'),
  },
  {
    path: '/returns',
    name: RouteNames.RETOURS,
    component: () => import('../views/RetoursConditions.vue'),
  },
  {
    path: '/contact-us',
    name: RouteNames.CONTACT_US,
    component: () => import('../views/ContactUs.vue'),
  },
    // old route with ortho error
  {
    path: '/engagement-rings-weding-rings-bespoke',
    redirect: to =>{
      return {name: RouteNames.SUR_MESURE}
    },
  },
  {
    path: '/engagement-rings-wedding-rings-bespoke',
    name:RouteNames.MARIAGE,
    redirect: to =>{
      return {name: RouteNames.SUR_MESURE}
    },
  },
  {
    path: '/events',
    name: RouteNames.EVENTS,
    component: () => import('../views/EventsView.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: RouteNames.NOT_FOUND,
    component: () => import('../views/NotFound.vue'),
  },
]

// NB: this line will be replaced by build script
const routes = routes_fr

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to,from, savedPosition) {
    const store = useScrollStore()
    if (to.name !== RouteNames.STORE){
      // For all other pages, scroll to top
      setTimeout(function () {
        return { top: 0,   left: 0,
          behavior:'auto' }
      },200);
    } else {
      setTimeout(function () {
        return { top: store.getScrollTop,   left: 0,
          behavior:'auto' }
      },200);
    }
  },
  routes
})

import Cookie from "@/cookie/cookies";
import Cookies from "js-cookie";
import {useScrollStore} from "@/scrollStore";

router.afterEach((to, from) => {

  // redirection from other language
  let userIdRoute = to.query.u
  if (typeof userIdRoute !== 'undefined' && userIdRoute !== '') {
    // this is a redirection from the other site
    Cookies.set('u', userIdRoute, {expires: 365});
  }

  // marketing
  const fbclid = to.query.fbclid;
  if(fbclid){
    localStorage.setItem('fbclid', fbclid);
    const unixEpoch = Math.floor(new Date().getTime() / 1000);
    localStorage.setItem('fbclid_time', unixEpoch);
  }
  const ttclid = to.query.ttclid;
  if(ttclid){
    localStorage.setItem('ttclid', ttclid);
    const unixEpoch = Math.floor(new Date().getTime() / 1000);
    localStorage.setItem('ttclid_time', unixEpoch);
  }

  let userId = ""
  if(Cookies.get('u')){
    userId = Cookies.get('u')
  }
  let additionalData = {
    locale:process.env.VUE_APP_SITE_LOCALE_META
  }
  let productId = ""
  if(to.params.productId){
    productId= to.params.productId
    additionalData.productId = productId
  }

  let consent = ""
  if (Cookies.get('cookieConsent')){
    consent = Cookies.get('cookieConsent')
  }
  if (to.query.utm_source && to.query.utm_source !== ""){
    localStorage.setItem("utm_source",to.query.utm_source)
  }

  Webservice.tracking({
    pageView:{
      referrer:document.referrer,
      domain: window.location.hostname,
      lang: navigator.language || navigator.userLanguage,
      to:to.path,
      from:from.path,
      currentUrl : window.location.href,
      consent:consent,
      productId:productId,
      routeName: to.name,
      meta:{
        fbc:Cookie.getCookie('_fbc'),
        fbp:Cookie.getCookie('_fbp'),
        fbclid:localStorage.getItem('fbclid'),
        fbclid_time: localStorage.getItem('fbclid_time'),
      },
      tt:{
        ttclid:localStorage.getItem('ttclid')
      },
      userId:userId,
      utm: {
        source: to.query.utm_source,
        medium: to.query.utm_medium,
        campaign: to.query.utm_campaign,
        term: to.query.utm_term,
        content: to.query.utm_content,
      },
      additionalData: additionalData,
    }
  })
})

export default router
